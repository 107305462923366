<template>
  <canvas id="video" width="854" height="530" style="width: 100%; background-color: #000;" />
  <!--
  <div v-if="!playing" class="video-preload">
    <img src="/ps/images/loading_anim_poseidon.gif" style="height: 100%;"/>
  </div>
  //-->
</template>

<style scoped>
  .video-preload {
    position: absolute;
    z-index: 99999999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    text-align: center;
    vertical-align: middle;
  }
</style>
<script>
export default {
  name: 'video-player-component',
  props: {
    tableKey: String,
    companyKey: String,
    serverUrl: String,
    mute: Boolean
  },
  data () {
    return {
      ck: '',
      tk: '',
      url: '',
      videoPlayer: null,
      playing: false,
      enableSound: true
    }
  },
  methods: {
    createVideo () {
      NodePlayer.debug(true)
      this.videoPlayer = new NodePlayer()

      this.videoPlayer.setView('video')
      this.videoPlayer.setVolume(this.enableSound === false ? 0 : 100)
      this.videoPlayer.setBufferTime(100)
      this.videoPlayer.setKeepScreenOn()

      const self = this
      this.videoPlayer.on('start', function() {
        self.playing = true
      })

      this.videoPlayer.on('error', function(e) {
        self.$log.debug(e)
      })
    },

    async getVideoUrl () {
      try {
        /*
        const result = await this.$API.game.getVideoUrl(this.serverUrl, this.ck, this.tk)
        if (result.code !== 'OK') {
          throw result
        }
        let serverUrl = this.serverUrl
        if (serverUrl[serverUrl.length-1] === '/') serverUrl = serverUrl.slice(0, serverUrl.length-1)
         */
        // this.url = serverUrl + result.url
        this.url = this.serverUrl
        this.createVideo()
        this.start()
      } catch(e) {
        this.$log.error(e)
        return false
      }
    },

    start () {
      this.videoPlayer.start(this.url)
    },

    stop () {
      this.videoPlayer.stop()
    },

    restart () {
      this.stop()
      this.start()
    },
    soundOnOff () {
      this.enableSound = !this.enableSound
    }
  },
  mounted () {
    let tk = this.tableKey
    if (this.companyKey === '88casino') {
      tk = 'bb' + this.tableKey
    }

    this.tk = tk
    this.ck = this.companyKey

    this.getVideoUrl().then()

    const self = this
    window.addEventListener('message', (event) => {
      if (event.data.type === 'callMethod') {
        const { methodName, args } = event.data;
        if (methodName === 'soundOnOff') {
          self.soundOnOff(args)
        }
      }
    });
  }
}
</script>