<template>
  <video-player :table-key="tableKey" :company-key="companyKey" :server-url="serverUrl" :mute="mute" v-if="companyKey && tableKey && serverUrl"></video-player>
</template>

<script>
import VideoPlayer from '@/components/game/video-player'

export default {
  name: "game-page",
  components: {
    VideoPlayer
  },
  data () {
    return {
      tableKey: '',
      companyKey: '',
      serverUrl: '',
      mute: false,
    }
  },
  methods: {

  },
  mounted() {
    this.companyKey = this.$route.query.companyKey
    this.tableKey = this.$route.query.tableKey
    this.serverUrl = this.$route.query.serverUrl
    this.mute = this.$route.query.sound === 'off'
  }
}
</script>